
import { Component, Vue } from 'vue-property-decorator'
import { Balance, BankTransfer, Buyer } from '@/models'

@Component({
  name: 'BuyersManagement',
  components: {
    BuyersManagementHeader: () => import('./components/BuyersManagementHeader.vue'),
    BuyerCard: () => import('./components/BuyerCard.vue'),
    TopupBuyerDialog: () => import('./components/TopupBuyerDialog.vue'),
    CreateBuyerDialog: () => import('./components/CreateBuyerDialog.vue'),
    ExtractDialog: () => import('@/components/ExtractDialog/ExtractDialog.vue')
  }
})
export default class extends Vue {
  private buyers: Buyer[] = []
  private totalBuyers = 0
  private buyersLoading = true

  private selectedBuyer: Buyer | null = null
  private topupDialogVisible = false

  private extractDialogVisible = false

  private createBuyerDialogVisible = false

  private listQuery: any = {
    buyerName: '',
  }

  private pagination: any = {
    page: 1,
    per: 10
  }

  created(): void {
    this.getBuyers()
  }

  private async getBuyers(): Promise<void> {
    this.buyersLoading = true

    const { data, meta } =
      await Buyer
        .where({ name: { match: this.listQuery.buyerName } })
        .includes(['balance', 'location'])
        .page(this.pagination.page)
        .per(this.pagination.limit)
        .order({ name: 'asc' })
        .stats({ total: 'count' })
        .all()

    this.buyers.push(...data)
    this.totalBuyers = meta.stats.total.count
    this.pagination.page += 1
    this.buyersLoading = false
  }

  handleCreateTopup(bankTransfer: BankTransfer): void {
    const buyerIndex = this.buyers.findIndex(buyer => buyer.id === this.selectedBuyer!.id)
    if (buyerIndex >= 0) {
      const buyerToUpdate = this.buyers[buyerIndex]

      if (buyerToUpdate.balance) buyerToUpdate.balance.amount += bankTransfer.amount
      else buyerToUpdate.balance = new Balance({ amount: bankTransfer.amount })

      this.buyers.splice(buyerIndex, 1, buyerToUpdate)
      this.selectedBuyer = null
      this.topupDialogVisible = false
    }
  }

  handleCreateBuyer(buyer: Buyer): void {
    this.buyers.push(buyer)
    this.selectedBuyer = null
    this.createBuyerDialogVisible = false
  }

  handleUpdateBuyer(buyer: Buyer): void {
    const buyerIndex = this.buyers.findIndex(buyer => buyer.id === this.selectedBuyer!.id)
    if (buyerIndex >= 0) {
      this.buyers.splice(buyerIndex, 1, buyer)
      this.selectedBuyer = null
      this.createBuyerDialogVisible = false
    }
  }

  private refresh(): void {
    this.buyers = []
    this.totalBuyers = 0
    this.pagination = {
      page: 1,
      per: 10
    }
    this.getBuyers()
  }
}
