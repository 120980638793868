
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Customer } from '@/models'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'CustomerCard',
  components: {
    PopoverVerifyCustomer: () => import('./PopoverVerifyCustomer.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) customer!: Customer

  userStore: any = useUserStore()

  get isCaretakerIEG(): boolean {
    return this.userStore.isCaretakerIEG
  }
}
