
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Customer } from '@/models'

@Component({
  name: 'PopoverVerifyCustomer'
})
export default class extends Vue {
  @Prop({ required: true }) customer!: Customer

  tempCustomer: Customer = new Customer({ program: { id: null } })
  popoverVisible = false
  loading = false

  get domain(): string {
    return `@${this.customer.businessEmail.split('@')[1]}`
  }

  async updateCustomer(isVerified: string): Promise<void> {
    this.loading = true

    Object.assign(this.tempCustomer, JSON.parse(JSON.stringify(this.customer)))
    this.tempCustomer.registrationStatus = isVerified

    try {
      await this.tempCustomer.save()

      this.popoverVisible = false
      this.$emit('update', this.tempCustomer.dup())

      this.$notify({
        title: this.$t('customers.notification.success.title') as string,
        message: this.$t('customers.notification.success.message') as string,
        type: 'success',
        duration: 2000
      })
    } catch (error) {
      this.$notify({
        title: this.$t('customers.notification.error.title') as string,
        message: this.$t('customers.notification.error.message') as string,
        type: 'error',
        duration: 2000
      })
    }

    this.loading = false
  }
}
